import React from 'react';
import logo from '../../../../assets/images/logo/header-logo.svg';
import './MobileHeader.scss';

const MobileHeader = () => {
  return (
    <nav role="navigation" className="flex-between mobile mobile-header">
      <a href="/" className="logo-wrapper">
        <img src={logo} alt="rath_eg logo" className="logo" />
      </a>

        <input type="checkbox" id="checkbox-mobile-nav" />
        <div>
          <a href="/contact" className="contact">
            <div className="contact-header">Get in touch</div>
          </a>
        </div>
        <label id="burger" htmlFor="checkbox-mobile-nav">
          <div></div>
          <div></div>
          <div></div>
        </label>
        <div></div>
        <nav id="mobile-menu">
          <a href="/tutoring">
            <div>Tutoring programs</div>
          </a>
          <a href="/educators">
            <div>For educators</div>
          </a>
          <a href="/about">
            <div>About us</div>
          </a>
          <a href="/contact" className="contact">
            <div>Get in touch</div>
          </a>
          <a
            href="https://secure.tutorcruncher.com/rathtutoring/login/"
            className="log-in"
            target="_blank"
          >
            <div>Log in</div>
          </a>
        </nav>
    </nav>
  );
};

export default MobileHeader;

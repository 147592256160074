import React from "react";
import logo from "../../../../assets/images/logo/header-logo.svg";
import "./DesktopHeader.scss";

const DesktopHeader = ({ path, admin }) => {
  return (
    <nav role="navigation">
      {admin ? (
        <div className="flex-between desktop desktop-header">
          <a href="/">
            <img src={logo} alt="rath_eg logo" className="logo" />
          </a>
          <div className="admin-header">{`ADMIN - ${admin.username}`}</div>
          <a className="header-link white" href="/users/sign_out">
            Log out admin
          </a>
        </div>
      ) : (
        <div className="flex-between desktop desktop-header">
          <a href="/">
            <img src={logo} alt="rath_eg logo" className="logo" />
          </a>
          <a
            className={`header-link ${path === "/tutoring" ? "selected" : ""}`}
            href="/tutoring"
          >
            <div>Tutoring programs</div>
          </a>
          <a
            className={`header-link ${path === "/educators" ? "selected" : ""}`}
            href="/educators"
          >
            <div>For educators</div>
          </a>
          <a
            className={`header-link ${path === "/about" ? "selected" : ""}`}
            href="/about"
          >
            <div>About us</div>
          </a>
          <a className="header-link cream" href="/contact">
            <div>Get in touch</div>
          </a>
          <a
            className="header-link white"
            href="https://secure.tutorcruncher.com/rathtutoring/login/"
            target="_blank"
          >
            Log in
          </a>
        </div>
      )}
    </nav>
  );
};

export default DesktopHeader;
